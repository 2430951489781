
export const SET_ERROR = 'SET_ERROR'
export function setError(error) {
  return {
    type: SET_ERROR,
    error
  }
}
export const RESET_ERROR = 'RESET_ERROR'
export function resetError() {
  return {
    type: RESET_ERROR
  }
}