export default {
  app: {
    error: 'Une erreur s\'est produite, veuillez réessayer plus tard.',
    price_unit: 'CHF',
    time_unit: 'h',
    nodates: 'Pas de dates de cours Disponibles',
    ok: 'Compris'
  },
  home: {
    title: 'Home',
    discover_more: 'En savoir plus',
    email: 'E-Mail',
    facebook: 'Facebook',
    instagram: 'Instagram'
  },
  courses: {
    title: 'Offre de cours',
    short: 'Abréviation',
    naehkurs: 'Cours de couture',
    themenkurs: 'Cours à thèmes',
    course_dates: 'Dates des cours',
    date: 'Date',
    dates: 'Dates',
    price: 'Prix',
    note: 'Commentaire',
    mat_inkl: 'Matériel inclus',
    mat_not_inkl: 'Matériel non-inclus',
    full: 'Complet',
    no_available_dates: 'Aucune donnée disponible pour le moment.'
  },
  signup: {
    title: 'Inscription',
    course: 'Cours',
    personal_details: 'Données personnelles',
    summary: 'Complète',
    continue: 'Continuer',
    back: 'Retour',
    complete: 'S\'inscrire',
    firstname: 'Prénom',
    lastname: 'Nom',
    email: 'E-Mail',
    phone: 'Numéro de téléphone',
    street: 'Rue Nr.',
    zipcode: 'Code postal',
    town: 'Ville',
    agb: 'Conditions d\'inscription',
    back_to_home: 'Retour à la page d\'accueil',
    reference: 'Référence de réservation',
    signup_invalid: 'Les données saisies n\'ont pas pu être validées.',
    course_full: 'Ce cours a déjà atteint le nombre maximum d\'inscriptions.'
  },
  atelier: {
    title: 'Atelier ouvert'
  },
  boerse: {
    title: 'Bourse aux cours',
    available_dates: 'Données disponibles sur les cours',
    no_available_dates: 'Actuellement, il n\'y a pas de données disponibles à la bourse.',
    discover_more: 'S\'inscrire',
    timeframe: 'Heure'
  },
  aboutme: {
    title: 'À propos de moi'
  },
  gallery: {
    title: 'Galerie'
  },
  contact: {
    title: 'Contact ',
    message: 'Message',
    send: 'Envoyer un commentaire',
    address: 'Adresse'
  },
  footer: {
    impressum: 'Mentions légales',
    datenschutz: 'Protection des données'
  },
  user: {
    login: 'Login',
    signup: 'S\'inscrire'
  },
  notfound: {
    title: 'La page ne sera pas trouvée.',
    back_to_home: 'Retour à la page d\'accueil',
  }
}