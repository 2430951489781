import React, { Suspense } from "react";
import { connect } from 'react-redux'
import { withFirebase } from '../../utils/firebase'
import { Translate as T } from 'react-redux-i18n'
import { setError, resetError } from '../../utils/actions'
import { HashRouter, Route, Switch } from 'react-router-dom';
import { animateScroll as scroll, scroller } from 'react-scroll'

import Loader from '../../templates/Loader'
import Error from '../../templates/Error'
import Nav from '../nav/Nav'
import Footer from '../footer/Footer'

import '../../pages/Pages.css'
import { Button, Link, Grid } from "@material-ui/core";
import Text from "../../templates/Text";

import { createBrowserHistory } from "history"

// home
import Home from '../../pages/home/Home'
// const Home = React.lazy(() => import('../../pages/home/Home'))

// courses
import CourseSewing from '../../pages/coursesewing/CourseSewing'
// const CourseSewing = React.lazy(() => import('../../pages/coursesewing/CourseSewing'))
import CourseTopics from '../../pages/coursetopics/CourseTopics'
//const CourseTopics = React.lazy(() => import('../../pages/coursetopics/CourseTopics'))
import Boerse from '../../pages/boerse/Boerse'
// const Boerse = React.lazy(() => import('../../pages/boerse/Boerse'))

// signup
const CourseSignUp = React.lazy(() => import('../../pages/coursesignup/CourseSignUp'))
const CourseSignUpSummary = React.lazy(() => import('../../pages/coursesignup/CourseSignUpSummary'))

// infopages
const Atelier = React.lazy(() => import('../../pages/Atelier'));
const AboutMe = React.lazy(() => import('../../pages/AboutMe'));
const Gallery = React.lazy(() => import('../../pages/Gallery'));
const Contact = React.lazy(() => import('../../pages/Contact'));

const Impressum = React.lazy(() => import('../../pages/Impressum'));
const Datenschutz = React.lazy(() => import('../../pages/Datenschutz'));

// backup
const NotFound = React.lazy(() => import('../../pages/NotFound'));



class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      active_path: 'home',
      loading: false,
      static: {},
      snackbar: false,
      message: ''
    }
  }

  componentDidMount() {
    window.addEventListener("hashchange", this._hashChange.bind(this), false);
    this._hashChange()
    this._cookie_message()
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this._hashChange.bind(this), false);
  }

  _hashChange() {
    const currentHash = window.location.hash.split('/')
    if (currentHash[1] !== '') {
      switch (currentHash[1]) {
        case 'home':
          if (currentHash[2] !== undefined) {
            this.setState({ active_path: currentHash[2] })
            this._scrollToElement(currentHash[2])
          } else {
            this.setState({ active_path: currentHash[1] })
            this._scrollToElement('top')
          }
          break;
        default:
          this.setState({ active_path: currentHash[1] })
          scroller.scrollTo('top', {
            duration: 100,
            offset: -56,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
          break;
      }
    } else {
      window.location.hash = 'home'
    }
  }

  _scrollToElement(id) {
    if (id === 'top') {
      scroll.scrollToTop({ duration: 500 })
    } else {
      scroller.scrollTo(id, {
        duration: 500,
        offset: -56,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }
  }

  _setStaticData(key, data) {
    this.setState({
      [key]: data
    })
  }

  _getStaticData(key) {
    return this.state.static[key] || null
  }

  _cookie_message() {
    const getCookie = (name) => {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }
    const cookie_message_seen = getCookie('_cookie_message_seen')

    if (!cookie_message_seen) {
      this.props.firebase.getStatic({ key: 'cookies' })
        .then((data) => {
          this.setState({
            snackbar: true,
            message: data.text
          })
        }
        )
        .catch((error) => this.props._setError(error))
    }
  }

  _cookie_message_close() {
    const setCookie = (name, value, days) => {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    setCookie('_cookie_message_seen', true, 365)
    this.setState({
      snackbar: false,
      message: ''
    })
  }

  render() {
    const { loading, active_path, snackbar, message } = this.state
    const { error } = this.props

    if (loading) {
      return (<Loader />)
    }

    return (
      <HashRouter>
        <Nav active_path={active_path} />
        {snackbar &&
          <div className='snackbar_wrapper'>
            <div className='snackbar_content'>
              <Text text={message} />
            </div>
            <Grid container className='snackbar_content'>
              <Grid item xs={6} >
                <Link href='#/datenschutz' color='secondary'>
                  <T value='footer.datenschutz' />
                </Link>
                {', '}
                <Link href='#/impressum' color='secondary'>
                  <T value='footer.impressum' />
                </Link>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <Button
                  onClick={this._cookie_message_close.bind(this)}
                  color='secondary'
                >
                  <T value='app.ok' />
                </Button>
              </Grid>
            </Grid>
          </div>
        }
        {error ?
          <Error error={error} reset={this.props._resetError} />
          :
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route path="/home" render={() => <Home />} />

              <Route path="/courses/sewing" render={() => <CourseSewing />} />
              <Route path="/courses/topics" render={() => <CourseTopics />} />
              <Route path="/courses/signup/summary/:signuiId/" render={() => <CourseSignUpSummary />} />
              <Route path="/courses/signup/:courseid/:dateid" render={() => <CourseSignUp />} />

              <Route path="/boerse/signup/summary/:signuiId/" render={() => <CourseSignUpSummary />} />
              <Route path="/boerse/signup/:courseid/:dateid" render={() => <CourseSignUp />} />
              <Route path="/boerse" render={() => <Boerse />} />

              <Route path="/aboutme" render={() => <AboutMe />} />
              <Route path="/gallery" render={() => <Gallery />} />
              <Route path="/contact" render={() => <Contact />} />

              <Route path="/impressum" render={() => <Impressum />} />
              <Route path="/datenschutz" render={() => <Datenschutz />} />

              <Route render={() => <NotFound />} />
            </Switch>
          </Suspense>
        }
        <Footer />
      </HashRouter >
    )
  }
}

const mapStateToProps = state => ({
  error: state.error
})
const mapDispatchToProps = dispatch => ({
  _setError: error => dispatch(setError(error)),
  _resetError: () => dispatch(resetError())
})

export default withFirebase(connect(mapStateToProps, mapDispatchToProps)(App))