import React, { Component } from 'react';
import { connect } from 'react-redux'
import { setError } from '../../../utils/actions'
import { withFirebase } from '../../../utils/firebase'
import { Translate as T } from 'react-redux-i18n'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
	Typography,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Grid,
	Button,
	Hidden
} from '@material-ui/core';
import {
	ExpandMore,
	EventBusy
} from '@material-ui/icons'
import Text from '../../../templates/Text'
import Loader from '../../../templates/Loader'

class CourseDates extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			expanded: false,
			seasons: null
		}
	}

	handleExpand() {
		const { loading, expanded } = this.state
		const { course } = this.props

		if (!expanded && loading && course._id) {
			this.props.firebase.getSeasons(course._id)
				.then((seasons) => this.setState({
					loading: false,
					seasons
				}))
				.catch((error) => this.props._setError(error))
		}
		this.setState({ expanded: !expanded })
	}

	_getDatesString(dates) {
		let timestring = ''
		dates.map((item, index) => {
			let date = new Date(parseInt(item.date))

			if (dates.length === 1) {
				timestring += ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
			} else if (index === dates.length - 1) {
				timestring += ' & ' + ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
			} else if (index === 0) {
				timestring += ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2)
			} else {
				timestring += ', ' + ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2)
			}
			return timestring
		})
		return timestring
	}

	render() {
		const { seasons, expanded, loading } = this.state
		const { course } = this.props

		return (
			<ExpansionPanel elevation={0} expanded={expanded} onChange={this.handleExpand.bind(this)}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMore color='secondary' />}
				>
					<Grid container>
						<Grid item xs={12} sm={8} md={8}>
							<Text text={[course.title]} />
						</Grid>
						<Grid item xs={12} sm={4} md={4}>
							<Typography>{course.timeframe.from} - {course.timeframe.to} <T value='app.time_unit' /></Typography>
						</Grid>
					</Grid>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					{loading && <Loader temporary={true} />}
					{!loading && seasons.length > 0 &&
						<Table size="small">
							<TableHead>
								<TableRow>
									<Hidden xsDown={true}>
										<TableCell><T value='courses.short' /></TableCell>
									</Hidden>
									<TableCell><T value='courses.dates' /></TableCell>
									<Hidden xsDown={true}>
										<TableCell align="right"><T value='courses.price' /></TableCell>
									</Hidden>
									<TableCell align="right"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{seasons.map((season) => (
									<TableRow key={season._id}>
										<Hidden xsDown={true}>
											<TableCell style={season.full ? { color: '#bbbbbb' } : {}}>
												{season.short + ' '}
											</TableCell>
										</Hidden>
										<TableCell component="th" scope="row" style={season.full ? { color: '#bbbbbb' } : {}}>
											<Hidden smUp={true}>{season.short}<br /></Hidden>
											{this._getDatesString(season.dates)}
											<Hidden smUp={true}><br /><T value='app.price_unit' />{' ' + season.price}</Hidden>
										</TableCell>
										<Hidden xsDown={true}>
											<TableCell align="right" style={season.full ? { color: '#bbbbbb' } : {}}>
												<T value='app.price_unit' />{' ' + season.price}
											</TableCell>
										</Hidden>
										<TableCell align="right">
											{season.full ?
												<Typography style={{ color: '#bbbbbb' }}>
													<T value='courses.full' />
												</Typography>
												:
												<Button
													color='secondary'
													onClick={() => {
														window.location.hash = 'courses/signup/' + course._id + '/' + season._id
													}}
												>
													<T value='user.signup' />
												</Button>
											}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					}
					{!loading && seasons.length <= 0 &&
						<div style={{ textAlign: 'center', width: '100%' }}>
							<Typography variant='subtitle1'>
								<EventBusy fontSize='large' /><br /><T value='courses.no_available_dates' />
							</Typography>
						</div>
					}
				</ExpansionPanelDetails>
			</ExpansionPanel>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	_setError: error => dispatch(setError(error))
})

export default withFirebase(connect(null, mapDispatchToProps)(CourseDates))