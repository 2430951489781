import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import Firebase, { FirebaseContext } from './utils/firebase';
import { ThemeProvider } from '@material-ui/styles';
import theme from './assets/theme'
import store from './utils/store'
import Router from './components/router/Router'
import './index.css';

ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <Router />
            </Provider>
        </ThemeProvider>
    </FirebaseContext.Provider>,
    document.getElementById('root'));

serviceWorker.unregister();