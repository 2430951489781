import React from 'react'
import {
  LinearProgress
} from '@material-ui/core'

export default (({ temporary }) => {
  if (temporary) {
    return (<LinearProgress style={{ position: 'fixed' }} className='app_loader' color='secondary' />)
  } else {
    return (
      <div>
        <LinearProgress style={{ position: 'fixed' }} className='app_loader' color='secondary' />
        <div className='app_content app_center' />
      </div>
    )
  }
})