import React from 'react'
import { Typography, Button } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error';
import { Translate as T } from 'react-redux-i18n'


export default function Error({ error, reset }) {

    return (
        <div className='app_content app_center'>
            <br /><br />
            <ErrorIcon style={{ fontSize: 40, color: '#a80000' }} />
            <br />
            <Typography variant='h6'><T value='app.error' /></Typography>
            <Typography variant='subtitle2'>{JSON.stringify(error)}</Typography>
            <br /><br />
            <Button onClick={() => reset()}>
                reset
            </Button>
        </div>
    )
}
