import React from 'react'
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core'


function CustomAppBar(props) {
    if (props.text)
        return props.text.map((item, key) => <Typography key={key} gutterBottom style={item.style} variant={item.variant} component="p" >{item[props.locale] || item.de || item.string}</Typography>)
    return ''
}

const mapStateToProps = state => ({
    locale: state.i18n.locale
})

export default connect(mapStateToProps)(CustomAppBar)