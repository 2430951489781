import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const theme = responsiveFontSizes(createMuiTheme({
    typography: {
        fontFamily: [
            '"Segoe UI"',
            'Roboto',
        ].join(','),
    },
    palette: {
        primary: {
            main: '#d86262',
        },
        secondary: {
            main: '#a80000',
        },
    },
}));

export default theme