import React from 'react'
import GridGallery from 'react-grid-gallery'


function Image({ height, width, image }) {
    return (

        <div style={{
            display: "block",
            height: height,
            width: width,
            overflowX: 'hidden',
            overflowY: 'auto'
        }}>
            <GridGallery
                images={[image]}
                enableImageSelection={false}
                showLightboxThumbnails={false}
                showImageCount={false}
            />
        </div>
    )
}

export default Image