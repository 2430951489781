import React, { Component } from 'react';
import { Element } from 'react-scroll';
import AppBar from './AppBar'
import Drawer from './Drawer'

import './Nav.css'

class Nav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawer_open: false,
      scrollposition: 0,
      height: 0,
      width: 0,
      popper_open: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this._scroll.bind(this))
    this._scroll()
    window.addEventListener('resize', this._resize.bind(this))
    this._resize()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._scroll.bind(this))
    window.removeEventListener('resize', this._resize.bind(this))
  }

  _resize() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      popper_open: false
    });
  }

  _scroll() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const scrolled = winScroll / height
    const scrollposition = scrolled ? scrolled : 0

    this.setState({
      scrollposition,
      popper_open: false
    })
  }

  handleTogglePopper() {
    this.setState(state => ({
      popper_open: !state.popper_open
    }))
  }

  _index_mapping = {
    'home': 0,
    'impressum': 0,
    'datenschutz': 0,
    'courses': 1,
    'sewingclass': 1,
    'topicsclass': 1,
    'atelier': 2,
    'boerse': 3,
    'aboutme': 4,
    'gallery': 5,
    'contact': 6,
  }

  _toggle_drawer() {
    this.setState(state => ({
      drawer_open: !state.drawer_open
    }))
  }

  render() {
    const { drawer_open, popper_open, scrollposition, width } = this.state
    const { active_path } = this.props

    return (
      <div className='nav_wrapper'>
        <Element name='top' />
        <AppBar
          width={width}
          selected_index={this._index_mapping[active_path]}
          scrollposition={scrollposition}
          toggle_drawer={this._toggle_drawer.bind(this)}
          popper_open={popper_open}
          toggle_popper={this.handleTogglePopper.bind(this)}
        />
        <Drawer
          drawer_open={drawer_open}
          toggle_drawer={this._toggle_drawer.bind(this)}
        />
      </div>
    )
  }
}

export default Nav