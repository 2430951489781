import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setError } from '../../utils/actions'
import { withFirebase } from '../../utils/firebase'
import Loader from '../../templates/Loader'
import Text from '../../templates/Text'
import { Grid, Divider } from '@material-ui/core'
import LupeIcon from '@material-ui/icons/Search'
import CourseTopicsDates from './elements/CourseTopicsDates'
import Image from '../../templates/Image'

class CourseTopics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      data: null,
      courses: null
    }
  }

  componentDidMount() {
    this.props.firebase.getStatic({ key: 'topic' })
      .then((data) => this.setState({
        data
      }))
      .then(() => this.props.firebase.getCourses('topic'))
      .then((courses) => this.setState({
        loading: false,
        courses
      }))
      .catch((error) => this.props._setError(error))
  }

  render() {
    const { data, courses, loading } = this.state
    const { locale } = this.props

    if (!data) { return (<Loader />) }

    return (
      <div className='app_content'>
        <Grid container className='app_padding'>
          <Grid item xs={12}>
            <Text text={data.text} />
            <br /><Divider />
          </Grid>
        </Grid>
        {data.topics.map((topic, index) => (
          <Grid container className='app_padding' key={index}>
            {topic.image ?
              <>
                <Grid item xs={12} md={10}>
                  <Text text={topic.text} />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Image
                    width='100%'
                    height='100%'
                    image={{
                      src: topic.image.src,
                      thumbnail: topic.image.thumbnail.src,
                      thumbnailWidth: topic.image.thumbnail.width,
                      thumbnailHeight: topic.image.thumbnail.height,
                      caption: topic.image.caption ? (topic.image.caption[locale] || topic.image.caption['de'] || '') : '',
                      customOverlay: (
                        <div className='image_overlay'>
                          <LupeIcon className='image_overlay_icon' color='secondary' fontSize='large' />
                        </div>
                      )
                    }}
                  />
                </Grid>
              </>
              :
              <Grid item xs={12} md={12}>
                <Text text={topic.text} />
              </Grid>
            }
            {courses ?
              <Grid item xs={12} >
                <br />
                {topic.subtopics ? topic.subtopics.map((subtopic) => (
                  <CourseTopicsDates
                    key={subtopic.topicId}
                    course={courses.filter(({ _id }) => _id === subtopic.topicId)[0]}
                    subtext={subtopic.text}
                  />
                ))
                  :
                  <CourseTopicsDates course={courses.filter(({ _id }) => _id === topic.topicId)[0]} />
                }
                <Divider />
              </Grid>
              :
              <Loader />
            }
          </Grid>
        ))}
      </div>
    )
    /*{data.topics.map((topic, index) => (
      <Topic key={index} topic={topic} courses={this._getCourses(courses, topic.topic, topic.topics)} setError={this.props.setError} />)
    )}*/
  }
}

const mapStateToProps = state => ({
  locale: state.i18n.locale
})

const mapDispatchToProps = dispatch => ({
  _setError: error => dispatch(setError(error))
})

export default withFirebase(connect(mapStateToProps, mapDispatchToProps)(CourseTopics))